import Vue from 'vue'
import App from './App.vue'
import VueHtml2Canvas from 'vue-html2canvas';
import Buefy from 'buefy';
import regeneratorRuntime from 'regenerator-runtime';
import router from './routes/index';
import 'buefy/dist/buefy.css';


Vue.config.productionTip = false
Vue.use(regeneratorRuntime);
Vue.use(VueHtml2Canvas);
Vue.use(Buefy);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');