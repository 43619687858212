/* eslint-disable */
import html2pdf from 'html2pdf.js';

export const getGmaps = (lat, long) => {
  let urlString =
    "http://maps.google.com/maps?z=12&t=m&q=loc:" + lat + "+" + long;
  return urlString;
};

export const exportToPDF = async (opt, content = htmlContent()) => {
  const defaultOptions = {
    filename: 'myfile.pdf',
    margin: 10,
    image: { type: 'jpeg', quality: 1 },
    html2canvas: { scale: 2 },
    jsPDF: { format: 'a4', orientation: 'portrait' },
  };

  await html2pdf()
    .set({ ...defaultOptions, ...opt })
    .from(content)
    .save();
};

const htmlContent = ({ content = '', styles = '' }) => `
    <!DOCTYPE html>
    <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Pdf Content</title>
            <style>
                ${styles}
            </style>
        </head>
        <body>
            ${content}
        </body>
    </html>
`;

export const templateHTML = {
  OrderList: (_preorder, _order = []) => {
    const generateFoodItem = (_foodItem = []) => {
      let listItem = `<div class="item-title text-header">Order List:</div>`;

      _foodItem.forEach((item) => {
        listItem += `<div class="item text-detail">${item.name} (x${item.amount})</div>`;
      });

      return listItem;
    };

    const generateAddress = (_address) => {
      if (!_address) {
        return ``;
      }

      return `
                <div class="flex-column">
                <a href=${getGmaps(_address.location.lat, _address.location.lng)}
                    <div>${_address.address}<div>
                </a>

                    ${_address.detail
          ? `<div class="mt-5">Details: ${_address.detail}<div>`
          : ``
        }
                </div>
                `;
    };

    const generateTableBody = (item) => {
      const resolveAddress = () => {
        if (item.preorder.type.code === 'meetingpoint') {
          return {
            pickup: '',
            delivery: item.address,
          };
        }

        return {
          pickup: _preorder.locations[0],
          delivery: item.address,
        };
      };

      const resolveCustomer = () => {
        const custPhone = item.customer.phone ? `${item.customer.phone.code}${item.customer.phone.number}` : ''
        const formattedCustPhone = custPhone ? `(+${custPhone})` : ''
        return `${item.customer.name} ${formattedCustPhone}`
      }

      let result = `
              <tr>
                <td style="word-wrap:break-word" class="text-detail">
                ${item.orderNumber}
                </td>
                <td class="text-detail">${resolveCustomer()}</td>
                <td class="address-column text-detail">
                ${generateAddress(resolveAddress().pickup)}
                </td>
                <td class="address-column text-detail">
                ${generateAddress(resolveAddress().delivery)}
                </td>
              </tr>
              <tr>
                <td colspan="4"><div>${generateFoodItem(item.items)}</div></td>
              </tr>
            `;

      return result;
    };

    const generateTable = () => {
      let table = '';

      _order
        .sort((a, b) => a.distance - b.distance)
        .forEach((item) => {
          table += `
                  <table>
                    <tr>
                      <th class="text-header">Order Number</th>
                      <th class="text-header">Customer</th>
                      <th class="text-header">Pickup Address</th>
                      <th class="text-header">Delivery Address</th>
                    </tr>
                    ${generateTableBody(item)}
                  </table>`;
        });

      return table;
    };

    const styles = `
                table {
                    font-family: arial, sans-serif;
                    border-collapse: collapse;
                    width: 100%;
                    margin-bottom: 20px;
                }
                
                td, th {
                    border: 1px solid #dddddd;
                    text-align: left;
                    padding: 8px;
                }
        
                td.address-column {
                    width: 30%
                }

                .text-header {
                  font-size: 16px;
                }

                .text-detail {
                  font-size: 14px;
                }
        
                .flex-column {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
        
                .mt-5 {
                    margin-top: 6px
                }
        
                .item-title {
                    font-weight: bold;
                    margin-bottom: 5px;
                }
        
                .item {
                    margin-bottom: 2px;
                }
            `;

    const content = `${generateTable()}`;

    return htmlContent({ content, styles });
  },
};
