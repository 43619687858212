/* eslint-disable */

import Vue from 'vue';
import Router from 'vue-router';
import PODeliveryDetails from '../components/PODeliveryDetails';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/PODeliveryDetails',
      name: 'PODeliveryDetails',
      component: PODeliveryDetails,
    },
  ],
});


export default router;
