<template>
  <div class="container pujasera-delivery-container">
    <div class="columns">
      <div class="column"><img src="@/assets/pujasera-logo.png" /></div>
      <div class="column">
      </div>
    </div>

    <b-field>
      <span v-html="generatedHTML"></span>
      <b-loading :is-full-page="isFullPage" v-model="isLoading"></b-loading>
    </b-field>
  </div>
</template>

<script>
import APIService from "../services/APIService";
import { templateHTML } from "../services/report";
export default {
  name: "PODeliveryDetails",
  props: {},
  data() {
    return {
      generatedHTML: "",
      orderDetails: [],
      isLoading: true,
      isFullPage: true,
    };
  },
  created() {
    this.isLoading = true;
    this.loadData();
  },
  methods: {
    loadData() {
      APIService.getOrderDeliveryData(this.$route.query.po).then((response) => {
        this.isLoading = false;
        this.orderDetails = response.data;
        this.generatedHTML = templateHTML.OrderList(
          this.orderDetails.preorder,
          this.orderDetails.data
        );
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
